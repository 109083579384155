export default {
  // we might not need this initialized state -> should remove
  initialized: false,
  loading: true,
  allChannels: [],
  currentChannel: null,
  channelListQuery: null,
  currentUserId: '',
  disableAutoSelect: false,
};
